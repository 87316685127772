// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/rqc-lib-react-portfolio/build/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

.app-portfolio-root {
  background-color: #0B192E;
  padding-top: 0.8rem;
}
.app-portfolio-root .content-center-sides {
  overflow-x: hidden;
  padding: 0 150px;
  max-width: 100%;
}
@media (max-width: 768px) {
  .app-portfolio-root .content-center-sides {
    padding: 0 5.5rem;
  }
}
@media (max-width: 576px) {
  .app-portfolio-root .content-center-sides {
    padding: 0 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/main.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,mBAAA;AAAF;AACE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AAAI;EAJF;IAKI,iBAAA;EAGJ;AACF;AAFI;EAPF;IAQI,iBAAA;EAKJ;AACF","sourcesContent":["@import '../../node_modules/rqc-lib-react-portfolio/build/index.css';\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.app-portfolio-root {\n  background-color: #0B192E;\n  padding-top: 0.8rem;\n  .content-center-sides {\n    overflow-x: hidden;\n    padding: 0 150px;\n    max-width: 100%;\n    @media (max-width: 768px) {\n      padding: 0 5.5rem;\n    }\n    @media (max-width: 576px) {\n      padding: 0 1.5rem;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
